import React from 'react';
import { Link } from 'gatsby';
import { CONTACTS_LINK } from '../../../constans/links';
import classes from './ContactInfo.module.scss';

const ContactInfo = ({ containerClasses }) => (
  <div className={`${classes.wrapper} ${containerClasses} t-500`}>
    * Please
    {' '}
    <Link to={CONTACTS_LINK} className={`${classes.inlinedLink} t-700`}>
      Contact Us
    </Link>
    {' '}
    through this online form if you are interested to join and receive more
    details
  </div>
);

export default ContactInfo;
