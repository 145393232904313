/* eslint-disable max-len */
import React from 'react';
import { Link } from 'gatsby';
import classes from './Faq.module.scss';
import FaqItem from './FaqItem/FaqItem';
import { CONTACTS_LINK } from '../../../constans/links';

const Faq = ({ toggleActiveTab }) => {
  const data = [
    {
      title: 'Joining requirements and process',
      paragraph: (
        <>
          <div>There are only 2 simple steps for a new supplier to join the platform:</div>
          <div className="ml-15 mb-1em">
            <div>
              A) Express your interest by submitting an online form with some
              high-level information about your property;
            </div>
            <div>
              B) Fill in and execute the relevant on-boarding documents after
              you receive them from Koralgo and submit them online
              Once processed, you will then receive your super-admin user
              details that can be used to set up your property(ies) details
              as well as provide access to other users involved in managing
              the property offers.
            </div>
          </div>
          <div>
            You can find more details on “how to join” on this
            {' '}
            {' '}
            <span onClick={() => toggleActiveTab('howToJoin')} className="inlined-link underlined light">page</span>
          </div>
        </>
      ),
    },
    {
      title: 'How is Koralgo different from other platforms and travel agencies?',
      paragraph: (
        <>
          <p>
            The services we provide to suppliers and end users are
            significantly incremental to that offered by other players
            in the market. Not only do we offer the same visibility and
            convenience as other platforms but the following principles
            and features truly set us apart:
          </p>
          <div className="ml-15">
            <div>A) Fees that are 35% to 50% lower than other platforms</div>
            <div>
              B) We do not compete against suppliers by mixing merchant
              and agency model and spending vast amounts of
              money in advertising (money coming from you by the way)
            </div>
            <div>
              C) We do not have special deals with certain suppliers
              and try to impose our pricing on you
            </div>
            <div>
              D) The concept of PV both permit a truly personalized
              ranking of search results and ensure that supplier
              offers find their natural consumer base without marketing spend.
            </div>
            <div>
              E) Consumers that value sound and progressive ethical,
              social and environment practices will value offers
              from suppliers that invest in those areas
            </div>
            <div>
              F) Innovation! Be it the ability to offer bespoke packages,
              compare different offerings, solve family
              room bedding / occupancy issues or upcoming ground-breaking
              analytics and advertising services.
              And we are just getting started!
            </div>
          </div>
        </>
      ),
    },
    {
      title: 'How can Koralgo provide more value at lower fees than its competitors?',
      paragraph: (
        <>
          <p>
            The answer to that is quite simple: our ability to provide value
            to both consumers and suppliers enable us to have a different business model and inherent cost structure:
          </p>
          <div className="ml-15">
            <p>
              A) Most of the other platforms only get traffic because they
              are visible, not because they offer anything new or of value
              to the consumer other than being a one-stop shop. However, to
              ensure they are visible they need to spend vast amounts in
              marketing, especially on search engines, so they appear in
              front of suppliers’ own websites! This, they can only afford
              to do by charging suppliers high transaction fees – so platforms
              are essentially “front trading” suppliers.
              Likewise, they offer “loyalty schemes” to end users to try to
              lock them in but ultimately it is suppliers that bear this
              incremental cost.
              O the contrary, Koralgo aims to attract consumers by providing
              unique features such as PV based search and ranking, not through
              search engine advertising.
            </p>
            <div className="mb-1em">
              <div>
                B) No cost duplication
              </div>
              We operate as an infrastructure, not a travel agency style of
              intermediary, so we do not get involved in dispute resolution
              other than providing supporting system-base technology to enable
              online booking, edits, cancellations, payments and refunds.
              Whilst the supplier would always have to be involved, this
              positioning avoids an extra set of cost to be incurred (that
              would be that of a powerless middleman) that would ultimately
              need to be recovered from suppliers by the platforms through
              higher fees.
            </div>
            <p>
              C) Lean organization with a focus on system-based work-streams
              We avoid the trappings of hierarchy and fat corporate costs by
              operating as a lean and flat organization. We also try to always
              future-proof our systems and ensure they are scalable so that what
              may seem a little time consuming on day one brings huge savings
              as your volume scales up. It is called smart investing!
            </p>
          </div>
        </>
      ),
    },
    {
      title: 'Pricing and charges of the platform',
      paragraph: (
        <>
          <p>
            Fees are expressed as a percentage of the transaction value
            (inclusive of GST/VAT if charged through the platform) and are
            paid to suppliers with invoicing on a monthly basis with
            adjustments
            depending on the percentage of inventory offered through Koralgo.
            Payment processing fees are borne by the end-user at the
            time of the transaction.
          </p>
          <p>
            Please contact us through this online
            {' '}
            <Link to={CONTACTS_LINK} className="inlined-link underlined light">form</Link>
            {' '}
            if you are interested to join and with to receive more details,
            including pricing.
          </p>
        </>
      ),
    },
    {
      title: 'Property / Activity ratings and reviews',
      paragraph: (
        <>
          <p>
            <p>
              - Are ratings compulsory and why should I request for one?
            </p>
            The ability to compute the PV is based on the existence of
            rating data in relation with the offer being available to the
            Koralgo platform so, at a minimum, a Desktop Review must be
            carried out.
          </p>
          <p>
            The main benefits or reviews and ratings are as follows:
          </p>
          <div className="mb-1em">
            <div>
              A) Certification effect and improved customer trust.
            </div>
            Similar to being featured in a guidebook, customers take comfort
            in seeing a professional unbiased third-party opinion which
            improves the probability of being selected
          </div>
          <p>
            B) On-site rating and Customer Experience improve the accuracy of
            “Desktop” rating data
          </p>
          <p>
            C) Balanced analysis and concept of meta-review help counter the
            negative bias introduced by one-off, and sometimes unjustified,
            negative reviews
            Recurring issues will be highlighted but clear fake and/or
            exaggerated guest reviews will be filtered out
            This also saves valuable time for the property or activity management
            team
          </p>
          <p>
            D) The report handed over by Koreval also indicates areas that
            need improvement
            If addressed, those will likely result in improved ratings when
            the next review occurs.
          </p>
          <p>
            - What is the content of review and what are the various
            options available?
          </p>
          <p>
            The reviews consist of:
          </p>
          <div className="ml-15 mb-1em">
            <div>▪ Description of activity or property</div>
            <div>▪ Comments over each rated category (e.g. convenience or comfort)</div>
            <div>▪ Meta-reviews or publicly available published reviews and comments; and</div>
            <div> ▪ Overall opinion and comments</div>
          </div>
          <p>
            3 types of ratings are available:
          </p>
          <div className="ml-15 mb-1em">
            <p>
              1) Desktop rating
            </p>
            <p>
              No formal “site visit” takes place and the analyst carries out
              all the research and analysis based on publicly available
              information
            </p>
            <div className="mb-1em">
              <div>
                2) On-site rating
              </div>
              A rating analyst adds a formal visit to the activity/property
              (guided by staff), including interview with the management,
              to the research work.
              The activity/property management also receives a detailed ratings report.
            </div>
            <div className="mb-1em">
              <div>
                3) On-site rating with Customer Experience
              </div>
              The formal on-site property visit is supplemented by an
              unscheduled and unannounced visit inclusive of overnight
              stay by a different reviewer.
              Similarly, in the case of an activity, an unannounced
              reviewer will experience the activity like a standard customer.
              This is highlighted in the displayed review and ratings
              section.
              The ratings report provided to management is even more
              comprehensive.
            </div>
          </div>
          <p>
            - How to request for rating services and what is the cost?
            Please use this contact
            {' '}
            <Link to={CONTACTS_LINK} className="inlined-link underlined light">form</Link>
            {' '}
            if you are interested in
          </p>
          <p>Koreval services</p>
          <div className="mb-1em">
            <div>
              - Which company carries out rating services?
            </div>
            At the moment, the only provider on Koralgo’s panel is Koreval,
            a business division of Koralgo (no conflict of interest in the
            way the operations are run). Over time, as the geographic
            footprint of the platform expands, other providers will also
            be included.
            Koralgo has strict quality benchmarks and processes that must
            be adhered to by ratings providers in order to qualify.
          </div>
        </>
      ),
    },
  ];

  return (
    <div className={`${classes.wrapper} fadeIn flex flex-col flex-grow-1`}>
      {data.map((faqItem, index) => (
        <FaqItem
          key={`seer${index}`}
          title={faqItem.title}
          paragraph={faqItem.paragraph}
        />
      ))}
    </div>
  );
};

export default Faq;
