import React from 'react';
import classes from './Bullets.module.scss';

const Bullets = ({ labels, activeLabel }) => (
  <div className={`${classes.wrapper} flex flex-h-center`}>
    {Object.keys(labels).map((label, index) => (
      <div
        key={`sdf${index}`}
        className={`${activeLabel === label ? classes.active : ''} ${
          classes.listItem
        }`}
        label={label}
      />
    ))}
  </div>
);

export default Bullets;
