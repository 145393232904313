import React from 'react';
import ContactInfo from '../ContactInfo/ContactInfo';
import classes from './PricingTiers.module.scss';

const PricingTiers = () => (
  <div className={`${classes.wrapper} fadeIn flex flex-col flex-grow-1`}>
    <div className={classes.textual}>
      <div className={`${classes.title} t-700`}>
        The base rate for the hospitality industry ranges from
        {' '}
        <span className={`${classes.markedText} t-700`}>
          7.5% to 9.0% of the transaction value
        </span>
        {' '}
        (inclusive of GST/VAT if charged through the platform).
      </div>
      <div className={`${classes.subTitle} t-700`}>
        The rate is determined on the basis of potential revenues
      </div>
      <div className={`${classes.descrItem} t-500`}>
        a function of the number of rooms and average price
      </div>
      <div className={`${classes.descrItem} t-500`}>
        adjustments depending on the percentage of inventory offered through
        Koralgo
      </div>
    </div>
    <ContactInfo />
  </div>
);

export default PricingTiers;
