import React from 'react';
import ArrowIcon from '../../../assets/img/icons/ic-reuse.svg';
import InfoIcon from '../../../assets/img/icons/ic-info-white.svg';
import ContactInfo from '../ContactInfo/ContactInfo';
import classes from './HowToJoin.module.scss';

const HowToJoin = () => (
  <div className={`${classes.wrapper} fadeIn flex flex-col flex-grow-1`}>
    <div className={`${classes.container}`}>
      <div className={`${classes.title} t-600`}>
        The process and list of required information and documents are as
        follows:
      </div>
      <div className={`${classes.content} flex flex-h-start flex-between`}>
        <div className={`${classes.item} flex flex-col flex-v-center`}>
          <div className={`${classes.itemHeader}`}>
            <div className={`${classes.textualTitle} t-600`}>step 1.</div>
          </div>

          <div className={`${classes.subTitle} t-600`}>
            Expression of interest by property
          </div>
        </div>

        <div className={`${classes.item} flex flex-col flex-v-center`}>
          <div className={`${classes.itemHeader}`}>
            <div
              className={`${classes.imagedTitle} flex flex-grow-0 flex-center`}
            >
              <ArrowIcon />
            </div>
          </div>
          <div className={`${classes.subTitle} t-600`}>
            Koralgo reviews these and sends:
          </div>
          <div className={classes.infoList}>
            <div className={`${classes.infoItem} ${classes.mainInfo} t-600`}>
              On-boarding forms
              <InfoIcon className={classes.infoItemIcon} />
              <div className={classes.mainInfoTip}>
                <div className={`${classes.mainInfoTipItem} t-500`}>
                  Recent certificate of good standing / search report or
                  business registration certificate (for partnerships or sole
                  proprietorship);
                </div>
                <div className={`${classes.mainInfoTipItem} t-500`}>
                  Current certificate of insurance (CoI);
                </div>
                <div className={`${classes.mainInfoTipItem} t-500`}>
                  Super-admin mandate form (Board Resolution or executed by CEO
                  / MD)
                </div>
              </div>
            </div>
            <div className={classes.infoItem}>Platform Terms of Service</div>
            <div className={classes.infoItem}>Applicable base rate</div>
          </div>
        </div>

        <div className={`${classes.item} flex flex-col flex-v-center`}>
          <div className={`${classes.itemHeader}`}>
            <div className={`${classes.textualTitle} t-600`}>step 2.</div>
          </div>
          <div className={`${classes.subTitle} t-600`}>
            Property submit electronic / scanned version of on-boarding
            documents
          </div>
        </div>

        <div className={`${classes.item} flex flex-col flex-v-center`}>
          <div className={`${classes.itemHeader}`}>
            <div
              className={`${classes.imagedTitle} flex flex-grow-0 flex-center`}
            >
              <ArrowIcon />
            </div>
          </div>
          <div className={`${classes.subTitle} t-600`}>
            Koralgo reviews these and provides approval and access to property
            super-admin
          </div>
        </div>
      </div>
    </div>
    <ContactInfo containerClasses={classes.contactInfoWrapper} />
  </div>
);

export default HowToJoin;
