import React from 'react';
import classes from './Benefits.module.scss';
import Icon from '../../../assets/img/icons/ic-square-check.svg';

const infoItemsArray = [
  {
    title:
      'Concept of PV results in a natural match to a seller’s target market without marketing spend',
    subtitle: [
      '- This ultimately result in optimized pricing since for the same budget, a seller with a specific value proposition will rank higher for a customer that is looking for those features /  this experience.',
    ],
  },
  {
    title:
      'Significantly lower transaction fees compared to current alternatives',
    subtitle: ['- 35% to 50% less than major platforms.'],
  },
  {
    title:
      'All the advantages of direct bookings with the increased visibility brought by a platform',
    subtitle: [
      '- No internal costs and investments required, unlike marketing to promote direct bookings.',
      '- Koralgo also provides the ability to offer bespoke packages',
    ],
  },
  {
    title:
      'Agency model only, so we never compete with you for inventory and always behave like a true partner',
  },
  {
    title:
      'PV concept and advanced technology will provide very distinctive and valuable analytic services',
  },
  {
    title: 'Balanced and professional reviews and ratings',
    subtitle: [
      '- Reduces the disproportionate impact and efforts required to manage the downside of unfair reviews',
    ],
  },
];

const Benefits = () => (
  <ul className={`${classes.list} flex flex-wrap fadeIn`}>
    {infoItemsArray.map((infoItem, index) => (
      <li key={`seoe${index}`} className={`${classes.listItem}`}>
        <Icon className={classes.icon} />
        <p className={`${classes.listItemTitle} t-600`}>{infoItem.title}</p>
        {infoItem.subtitle
          ? infoItem.subtitle.map((subtitle, subtitleIndex) => (
            <p
              key={`swdgyu${subtitleIndex}`}
              className={classes.listItemSubtitle}
            >
              {subtitle}
            </p>
          ))
          : null}
      </li>
    ))}
  </ul>
);

export default Benefits;
