import React from 'react';
import classes from './Nav.module.scss';

const Nav = ({
  labels, activeLabel, listClasses, onClick,
}) => (
  <div className={`${listClasses} flex`}>
    {Object.keys(labels).map((label, index) => (
      <div
        key={`sdsefsdf${index}`}
        onClick={() => onClick(label)}
        className={`${activeLabel === label ? classes.active : ''} ${
          classes.navItem
        } t-700`}
        label={label}
      >
        {labels[label].navText}
      </div>
    ))}
  </div>
);

export default Nav;
