import React, { useState } from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import Nav from '../../components/KoralgoForSeller/Nav/Nav';
import Benefits from '../../components/KoralgoForSeller/Benefits/Benefits';
import PricingTiers from '../../components/KoralgoForSeller/PricingTiers/PricingTiers';
import HowToJoin from '../../components/KoralgoForSeller/HowToJoin/HowToJoin';
import Faq from '../../components/KoralgoForSeller/Faq/Faq';
import Bullets from '../../components/KoralgoForSeller/Bullets/Bullets';
import classes from './index.module.scss';
import { EXPRESS_INTEREST_LINK } from '../../constans/links';

const labels = {
  benefits: { navText: 'Benefits of Koralgo', component: Benefits },
  pricing: { navText: 'Pricing Tiers', component: PricingTiers },
  howToJoin: { navText: 'How to join', component: HowToJoin },
  faq: { navText: 'FAQ for Sellers', component: Faq },
};

const Index = () => {
  const [activeLabel, setActiveLabel] = useState(Object.keys(labels)[0]);

  const toggleActiveTab = (label) => {
    setActiveLabel(label);
  };

  const TabToDisplay = labels[activeLabel].component;

  return (
    <Layout mainClasses="flex-grow-1 flex">
      <div className={`${classes.wrapper} flex flex-col`}>
        <div
          className={`${classes.container} landing-container flex flex-col flex-grow-1`}
        >
          <div
            className={`${classes.header} flex flex-v-center flex-between flex-wrap`}
          >
            <div className={classes.navListWrapper}>
              <Nav
                listClasses={classes.navListClasses}
                labels={labels}
                activeLabel={activeLabel}
                onClick={toggleActiveTab}
              />
            </div>
            <div className={`${classes.infoLabel} flex flex-v-center`}>
              <p className={`${classes.infoLabelText} t-500`}>
                There are only
                {' '}
                <span className="t-700">2 simple steps</span>
                {' '}
                to
                become a seller.
              </p>
              <Link
                to={EXPRESS_INTEREST_LINK}
                className={`${classes.infoLabelButton} btn t-600 btn-uppercased`}
              >
                <span className="btn__text">Become a seller</span>
              </Link>
            </div>
          </div>
          <TabToDisplay
            toggleActiveTab={toggleActiveTab}
          />
        </div>
        <Bullets labels={labels} activeLabel={activeLabel} />
      </div>
    </Layout>
  );
};

export default Index;
